$white: #fff;
$black: #000;
$black-282828: #282828;
$black-18191b: #18191b;
$black-393b3d: #393b3d;
$grey-light: rgb(151, 151, 151);
$grey-very-light: #f3f3f3;
$grey-e1e1e1: #e1e1e1;
$grey-eaeaea: #eaeaea;
$grey-dcdcdc: #dcdcdc;
$grey-d8d8d8: #d8d8d8;
$grey-979797: #979797;
$grey-959595: #959595;
$grey-a1a1a1: #a1a1a1;
$grey-5c5c5c: #5c5c5c;
$grey-d7d7d7: #d7d7d7;
$grey-aaa: #aaa;
$grey-dbdbdb: #dbdbdb;
$grey-b1b1b1: #b1b1b1;
$grey-e8e8e9: #e8e8e9;
$grey-b0b0b0: #b0b0b0;
$grey-626262: #626262;
$grey-f0f0f0: #f0f0f0;
// brand colors
$brand-primary: #473bff;
$brand-secondary: #a167ff;

// interface colors
$body-bg: $white;
$light-bg: $white;
$light-bg-secondary: #F5F5F7;
$light-body: #282828;
$light-border: #e2e2e2;
$internal-body-bg: #f9f9f9;
$filter-bg-dark: #282828;
$custom-dialog-bg: #f3f3f3;
$overlay-bg: #f9f9f9;
$filter-text-white: #fff;
$filter-bg-white: #fff;
$input-bg: #f5f5f5;
$text-dark: #282828;
$text-grey: #c0c0c0;
$text-grey-darker: #9b9b9b;
$share-btn-hover: #f0f1f6;
$sidenav-dark: #282828;
$sidenav-dark-item-active: #373737;

$guest-list-title: #dd6a9b;
$guest-list-dialog: #fcf0f5;
$indigo: #3a4eff;

$whatsapp-background: #efe8e0;
$facebook: #1977f2;
$linkedin: #0966c2;
$instagram: #ff0275;
$youtube: #ff0000;
$tiktok: #000000;
$link: #1977f2;
$dark: #282828;

$user-check: #27b8a1;

//data-fields
$short-data-field: #bbf9da;
$multiple-choice-data-field: #ffa901;
$yes-no-data-field: #e3d629;
$ranking-data-field: #dd6060;
$number-data-field: #27b8a1;

// var colors
:root {
  --normal-dialog-event-background-color: #f3f3f3;
  --mat-form-field-background-color: #f3f3f3;
  --mat-form-field-border-color: transparent;
  --mat-form-field-focused-border-color: #473bff;
  --community-mat-form-field-focused-border-color: #ffffff;
  --mat-form-field-input-color: #282828;
  --mat-form-field-label-color: rgba(0, 0, 0, 0.6);
  --mat-form-field-label-focused-color: #473bff;

  --community-form-field-label-focused-color: #ffffff;

  --event-theme-primary-glow: rgba(255, 58, 170, 0.5);

  --event-theme-primary-color: #282828;
  --event-theme-secondary-color: #f3f3f3;
  --event-theme-ternary-color: #f3f3f3;
  --event-theme-accent-color: #f3f3f3;
  --event-theme-pop-color: #f3f3f3;
  --event-theme-action-color: #473bff;
  --event-theme-primary-text-color: #282828;
  --event-theme-primary-text-opposite-color: #ffffff;
  --event-theme-accent-text-color: #282828;
  --event-theme-pop-text-color: #282828;
  --event-theme-brand-color: #473bff;
  --event-theme-signal-color: #282828;
  --event-theme-body-color: #282828;
  --event-theme-background-color: #ffffff;
  --event-theme-back: #f3f3f3;
  --event-theme-front: #473bff;
  --event-theme-primary-color-opacity: rgba(#282828, 0.5);
  --event-theme-actions-bg-color: rgba(#282828, 0.1);
  --event-theme-actions-bg-color-hover: rgba(#282828, 0.2);

  --rsvp-dialog-background-color: rgba(22, 15, 18, 0.15);
  --rsvp-dialog-body-color: #ffffff;
  --rsvp-dialog-backdrop-filter: blur(10px);
  --event-theme-rsvp-primary-color: #473bff;
  --event-theme-rsvp-primary-text-color: #ffffff;
  --event-theme-rsvp-action-color: #ffffff;
  --mobile-nav-backdrop-filter: blur (47.5px);

  // weddingpage sections
  --event-section-headline-color: #af9072;
  --event-section-headline-background-color: #f1ebe0;
  --event-section-body-color: #637d7f;
  --event-section-background-color: #faf8f0;
  --event-section-main-body-color: #637d7f;
  --event-section-main-background-color: #faf8f0;

  --event-section-accent-headline-color: #faf8f0;
  --event-section-accent-headline-background-color: #768c8d;
  --event-section-accent-body-color: #faf8f0;
  --event-section-accent-background-color: #637d7f;

  // communities
  --community-background-color: #000000;
  --community-title-color: #ffffff;
  --community-body-color: #ffffff;
}

body {
  // platform colors
  --bg: #ffffff;
  --bg-secondary: #f5f5f7;
  --bg-tertiary: #eaeaef;
  --border-color: #e2e2e2;
  --text-primary: #282828;
  --text-secondary: #606060;
  --text-tertiary: #b0b0b0;
  --text-brand-primary: #473bff;
  --brand-primary: #473bff;
  --brand-secondary: #7897ff;
  --extra-green: #33b977;
  --extra-orange: #ffa723;
  --extra-red: #d72323;
  --extra-blue: #37b8f9;
  --extra-pink: #e143b4;
  --text-on-primary: #ffffff;
  --text-on-orange: #282828;
  --brand-primary-real: #473bff;
  --brand-secondary-real: #7897ff;

  --bg-opacity-80: rgba(255, 255, 255, 0.8);
  --text-primary-opacity-20: rgba(40, 40, 40, 0.2);
  --brand-primary-opacity-10: rgba(71, 59, 255, 0.1);

  --mat-calendar-selected: var(--brand-primary);
  --mat-calendar-hover: rgba(71, 59, 255, 0.3);
  --mat-calendar-range: rgba(71, 59, 255, 0.3);

  --use-case-card-hover: #eaf3fe;
  --icon-button-hover: rgba(71, 59, 255, 0.11);

  --dark-text-primary: #e8e8e9;
  --dark-text-secondary: #b0b0b0;

  --light-text-primary: #282828;
  --light-text-secondary: #606060;

  &.platform-weddingpage {
    // platform colors
    --text-brand-primary: #e7be61;
    --brand-primary: #ffdd8f;
    --brand-secondary: #ffe7b1;
    --text-on-primary: #282828;
    --text-on-orange: #282828;
    --brand-primary-real: #ffdd8f;
    --brand-secondary-real: #ffe7b1;

    --mat-calendar-selected: var(--brand-primary);
    --mat-calendar-hover: rgba(255, 221, 143, 0.3);
    --mat-calendar-range: rgba(255, 221, 143, 0.3);

    --use-case-card-hover: #fffcf2;
    --icon-button-hover: rgba(255, 221, 143, 0.11);

    --brand-primary-opacity-10: rgba(255, 221, 143, 0.1);
  }

  --body-bg-color: var(--bg);
  --internal-body-bg-color: var(--bg);
  --body-color: #282828;
  --brand-tertiary: #d8d8d8; // not used
  --text-light: #ffffff;
  --text-btn: #eaf3fe;
  --accordion-content-color: #ffffff;
  --event-tab: #f0f1f6;
  --table-bg: #fbfbfb;
  --dialog-bg: #ffffff;
  --tab-bg: var(--bg-secondary);
  --tab-color: var(--text-primary);
  --hover-color: rgba(71, 59, 255, 0.1);
  --notification-success-color: var(--brand-primary);
  --notification-success-text-color: var(--text-on-primary);
  --mobile-nav-background-color: rgba(243, 243, 243, 0.95);
  --mobile-nav-background-color-full-opacity: rgba(243, 243, 243);
  --description-color: #282828;

  --const-dark-color: #282828;
  --const-light-color: #ffffff;

  --ai-chat-background: #ffffff;
  --ai-button-selected-background: #f2f3f9;
  --form-background: #f9f9f9;
  --filter-selected-status: #f3f3f3;
  --slide-toggle-active: var(--brand-primary);
  --normal-dialog-background-color: #ffffff;
  --selected-field: #f3f3f3;

  --brand-primary-filter: invert(19%) sepia(93%) saturate(3211%)
    hue-rotate(242deg) brightness(98%) contrast(107%);

  --rsvp-background-color: rgba(22, 15, 18, 0.15);
  --rsvp-backdrop-filter: blur(50px);
  --rsvp-hover-color: hsla(0, 0%, 100%, 0.5);

  --background-color-alpha: rgba(249, 249, 249, 0.5);
  --backdrop-filter-alpha: blur(25px);

  --event-link-bio-background-color: var(--brand-primary);
  --event-link-bio-title-color: #ffffff;
  --event-link-bio-body-color: #ffffff;

  --event-edit-field-bg-color: rgba(22, 15, 18, 0.05);

  --user-profile-edit-bg-color: rgba(22, 15, 18, 0.05);

  // material
  --mat-form-field-background-color: var(--bg-tertiary);
  --mat-form-field-focused-background-color: var(--bg-secondary);
  --mat-form-field-border-color: transparent;
  --mat-form-field-focused-border-color: var(--brand-primary);
  --mat-form-field-input-color: var(--text-primary);
  --mat-form-field-label-color: var(--text-secondary);
  --mat-form-field-label-focused-color: var(--brand-primary);
  --mat-drawer-background: #282828;
  --mat-datepicker-disabled-date-label: rgba(0, 0, 0, 0.38);
  --mat-calendar-selected-date: var(--text-on-primary);
  --mat-daterange-picker-selected-time: #282828;
  --mat-daterange-picker-selected-date: var(--text-secondary);

  --mat-form-field-disabled-input-color: rgba(0, 0, 0, 0.38);
  --mat-form-field-disabled-label-color: rgba(0, 0, 0, 0.38);

  --timezone-select-label-color: rgba(0, 0, 0, 0.6);

  --selected-action: #f3f3f3;
  --action-bar-hover: hsla(0, 0%, 100%, 0.2);
  --avatar-background: var(--bg-secondary);

  --system-dialog-bg-color: #ffffff;

  --color-palette-background: #efefef;
  --color-palette-border: #f3f3f3;

  --table-bg-white: var(--bg);
  --table-bg-grey: var(--bg-secondary);
  --table-bg-grey-row: rgba(245, 245, 247, 0.5);
  --table-row-hover: var(--bg-secondary);

  --opacity-dialog: rgba(255, 255, 255, 0.7);
  --background-blurred: rgba(255, 255, 255, 0.95);

  --referral-card-cutout: #fff;

  --profile-events-bg: #f3f3f3;
  --user-avatar-shadow: var(--brand-primary);

  --create-event-start-type-bg: #f3f3f3;

  --card-bg: var(--bg-secondary);

  --dashboard-statistic-card-bg: var(--bg-secondary);

  --dashboard-navigation-card-bg: var(--bg-secondary);
  --dashboard-navigation-card-border: var(--bg-secondary);

  --bottom-sheet-dialog-bg: rgba(232, 232, 233, 0.6);

  --dialog-backdrop-bg: rgba(243, 243, 243, 0.8);
  --secondary-dialog-backdrop-bg: rgba(255, 255, 255, 0.8);

  &.dark-mode {
    // platform colors
    --bg: #18191b;
    --bg-secondary: #2c2f3a;
    --bg-tertiary: #1f212a;
    --border-color: #393b3d;
    --text-primary: #e8e8e9;
    --text-secondary: #b0b0b0;
    --text-tertiary: #8e8e8e;
    --text-brand-primary: #abef5e;
    --brand-primary: #abef5e;
    --brand-secondary: #ee075e;
    --extra-green: #9af797;
    --extra-orange: #f6bb63;
    --extra-red: #fe7070;
    --extra-blue: #8dd9ff;
    --extra-pink: #ff93e0;
    --text-on-primary: #18191b;
    --text-on-orange: #18191b;
    --brand-primary-real: #ee075e;
    --brand-secondary-real: #abef5e;

    --bg-opacity-80: rgba(24, 25, 27, 0.8);
    --text-primary-opacity-20: rgba(232, 232, 232, 0.2);
    --brand-primary-opacity-10: rgba(171, 239, 94, 0.1);

    --body-bg-color: #090909;
    --internal-body-bg-color: #090909;
    --body-color: #e8e8e9;
    --brand-tertiary: #ee075e;
    --text-light: #282828;
    --text-btn: #282828;
    --accordion-content-color: #393b3d;
    --event-tab: #282828;
    --tab-bg: var(--bg-secondary);
    --tab-color: var(--text-primary);
    --avatar-background: #090909;
    --ai-chat-background: #090909;
    --ai-button-selected-background: #282828;
    --form-background: #160f12;
    --filter-selected-status: #282828;
    --slide-toggle-active: #abef5e;
    --normal-dialog-background-color: var(--bg-secondary);
    --selected-field: #abef5e;
    --table-bg: #575656;
    --dialog-bg: #575656;
    --hover-color: #282828;
    --notification-success-color: #abef5e;
    --mobile-nav-background-color: rgba(50, 49, 69, 0.95);
    --mobile-nav-background-color-full-opacity: rgba(50, 49, 69);

    --brand-primary-filter: brightness(0) saturate(100%) invert(83%) sepia(85%)
      saturate(373%) hue-rotate(29deg) brightness(102%) contrast(87%);

    --mat-form-field-background-color: var(--bg-tertiary);
    --mat-form-field-focused-background-color: var(--bg-secondary);
    --mat-form-field-border-color: var(--border-color);
    --mat-form-field-focused-border-color: var(--brand-primary);
    --mat-form-field-input-color: var(--text-primary);
    --mat-form-field-label-color: var(--text-secondary);
    --mat-form-field-label-focused-color: var(--brand-primary);
    --mat-drawer-background: #282828;
    --mat-datepicker-disabled-date-label: rgba(255, 255, 255, 0.5);
    --mat-calendar-selected-date: #282828;
    --mat-daterange-picker-selected-time: var(--text-on-primary);
    --mat-daterange-picker-selected-date: var(--bg-secondary);

    --mat-form-field-disabled-input-color: rgba(232, 232, 233, 0.38);
    --mat-form-field-disabled-label-color: rgba(232, 232, 233, 0.38);
    --rsvp-background-color: rgba(22, 15, 18, 0.15);
    --background-color-alpha: rgba(40, 40, 40, 0.5);
    --timezone-select-label-color: rgba(255, 255, 255, 0.6);

    --mat-calendar-selected: #ffffff;
    --mat-calendar-hover: #f3f3f3;
    --mat-calendar-range: #f3f3f3;

    --icon-button-hover: rgba(171, 239, 94, 0.3);

    --selected-action: #282828;
    --action-bar-hover: hsla(0, 0%, 100%, 0.2);

    --system-dialog-bg-color: #282828;

    --use-case-card-hover: #090909;

    --color-palette-background: #282828;
    --color-palette-border: #575656;

    --table-bg-white: var(--bg);
    --table-bg-grey: var(--bg-secondary);
    --table-bg-grey-row: rgba(44, 47, 58, 0.5);
    --table-row-hover: var(--bg-secondary);

    --event-link-bio-background-color: #abef5e;
    --event-link-bio-title-color: #ffffff;
    --event-link-bio-body-color: #ffffff;

    --user-profile-edit-bg-color: rgb(60, 60, 60);

    --opacity-dialog: rgba(22, 15, 18, 0.7);
    --background-blurred: rgba(24, 25, 27, 0.95);

    --referral-card-cutout: rgba(22, 15, 18);

    --profile-events-bg: #161616;
    --user-avatar-shadow: #ffffff;

    --create-event-start-type-bg: #282828;

    --card-bg: var(--bg-secondary);

    --dashboard-statistic-card-bg: var(--bg-secondary);

    --dashboard-navigation-card-bg: transparent;
    --dashboard-navigation-card-border: #393b3d;

    --bottom-sheet-dialog-bg: rgba(24, 25, 27, 0.8);

    --dialog-backdrop-bg: rgba(28, 28, 28, 0.8);
    --secondary-dialog-backdrop-bg: rgba(24, 25, 27, 0.8);
    --description-color: #b0b0b0;

    @media only screen and (max-width: 768px) {
      --user-profile-edit-bg-color: rgb(40, 40, 40);
    }

    &.platform-eventpage {
      // platform colors
      --bg: #18191b;
      --bg-secondary: #2c2f3a;
      --bg-tertiary: #1f212a;
      --border-color: #393b3d;
      --text-primary: #e8e8e9;
      --text-secondary: #b0b0b0;
      --text-tertiary: #8e8e8e;
      --text-brand-primary: #7897ff;
      --brand-primary: #7897ff;
      --brand-secondary: #bbd4ff;
      --extra-green: #9af797;
      --extra-orange: #f6bb63;
      --extra-red: #fe7070;
      --extra-blue: #8dd9ff;
      --extra-pink: #ff93e0;
      --text-on-primary: #18191b;
      --text-on-orange: #18191b;
      --brand-primary-real: #7897ff;
      --brand-secondary-real: #bbd4ff;

      --bg-opacity-80: rgba(24, 25, 27, 0.8);
      --text-primary-opacity-20: rgba(232, 232, 232, 0.2);
      --brand-primary-opacity-10: rgba(120, 151, 255, 0.1);

      --body-bg-color: #18191b;
      --internal-body-bg-color: #18191b;
      --avatar-background: #18191b;
      --ai-chat-background: #18191b;
      --normal-dialog-background-color: var(--bg-secondary);
      --use-case-card-hover: #18191b;
      --table-bg-white: #18191b;

      --brand-tertiary: var(--bg);
      --text-light: #ffffff;
      --text-btn: #eaf3fe;
      --slide-toggle-active: var(--brand-primary);
      --selected-field: var(--brand-primary);

      --brand-primary-filter: invert(19%) sepia(93%) saturate(3211%)
        hue-rotate(242deg) brightness(98%) contrast(107%);

      --mat-form-field-background-color: var(--bg-tertiary);
      --mat-form-field-focused-background-color: var(--bg-secondary);
      --mat-form-field-border-color: var(--border-color);
      --mat-form-field-focused-border-color: var(--brand-primary);
      --mat-form-field-input-color: var(--text-primary);
      --mat-form-field-label-color: var(--text-secondary);
      --mat-form-field-label-focused-color: #ffffff;
      --mat-drawer-background: #282828;
      --mat-datepicker-disabled-date-label: rgba(255, 255, 255, 0.5);
      --mat-daterange-picker-selected-time: var(--text-on-primary);
      --mat-daterange-picker-selected-date: var(--bg-secondary);

      --icon-button-hover: rgba(120, 151, 255, 0.11);

      --event-link-bio-background-color: var(--brand-primary);
    }

    &.platform-weddingpage {
      // platform colors
      --bg: #18191b;
      --bg-secondary: #2c2f3a;
      --bg-tertiary: #1f212a;
      --border-color: #393b3d;
      --text-primary: #e8e8e9;
      --text-secondary: #b0b0b0;
      --text-tertiary: #8e8e8e;
      --text-brand-primary: #e7be61;
      --brand-primary: #e7be61;
      --brand-secondary: #ffdd8f;
      --extra-green: #9af797;
      --extra-orange: #f6bb63;
      --extra-red: #fe7070;
      --extra-blue: #8dd9ff;
      --extra-pink: #ff93e0;
      --text-on-primary: #18191b;
      --text-on-orange: #18191b;
      --brand-primary-real: #e7be61;
      --brand-secondary-real: #ffdd8f;

      --bg-opacity-80: rgba(24, 25, 27, 0.8);

      --body-bg-color: #18191b;
      --internal-body-bg-color: #18191b;
      --avatar-background: #18191b;
      --ai-chat-background: #18191b;
      --normal-dialog-background-color: var(--bg-secondary);
      --use-case-card-hover: #18191b;
      --table-bg-white: #18191b;

      --brand-tertiary: var(--bg);
      --text-light: #ffffff;
      --text-btn: #eaf3fe;
      --slide-toggle-active: var(--brand-primary);
      --selected-field: var(--brand-primary);

      --brand-primary-filter: invert(19%) sepia(93%) saturate(3211%)
        hue-rotate(242deg) brightness(98%) contrast(107%);

      --mat-form-field-background-color: var(--bg-tertiary);
      --mat-form-field-focused-background-color: var(--bg-secondary);
      --mat-form-field-border-color: var(--border-color);
      --mat-form-field-focused-border-color: var(--brand-primary);
      --mat-form-field-input-color: var(--text-primary);
      --mat-form-field-label-color: var(--text-secondary);
      --mat-form-field-label-focused-color: #ffffff;
      --mat-drawer-background: #282828;
      --mat-datepicker-disabled-date-label: rgba(255, 255, 255, 0.5);
      --mat-daterange-picker-selected-time: var(--text-on-primary);
      --mat-daterange-picker-selected-date: var(--bg-secondary);

      --icon-button-hover: rgba(231, 190, 97, 0.11);

      --event-link-bio-background-color: var(--brand-primary);
    }
  }
}

// platform colors
$bg: var(--bg);
$bg-secondary: var(--bg-secondary);
$bg-tertiary: var(--bg-tertiary);
$border-color: var(--border-color);
$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
$text-tertiary: var(--text-tertiary);
$text-brand-primary: var(--text-brand-primary);
$brand-primary: var(--brand-primary);
$brand-secondary: var(--brand-secondary);
$extra-green: var(--extra-green);
$extra-orange: var(--extra-orange);
$extra-red: var(--extra-red);
$extra-blue: var(--extra-blue);
$extra-pink: var(--extra-pink);
$text-on-primary: var(--text-on-primary);
$text-on-orange: var(--text-on-orange);
$brand-primary-real: var(--brand-primary-real);
$brand-secondary-real: var(--brand-secondary-real);

$bg-opacity-80: var(--bg-opacity-80);
$text-primary-opacity-20: var(--text-primary-opacity-20);
$brand-primary-opacity-10: var(--brand-primary-opacity-10);

$dark-text-primary: var(--dark-text-primary);
$dark-text-secondary: var(--dark-text-secondary);

$light-text-primary: var(--light-text-primary);
$light-text-secondary: var(--light-text-secondary);

$success: var(--extra-green);
$danger: var(--extra-red);
$warning: var(--extra-orange);
$info-box: var(--extra-orange);

$community-body-color: var(--community-body-color);
$community-title-color: var(--community-title-color);
$community-background-color: var(--community-background-color);
$brand-tertiary: var(--brand-tertiary);
$notificationSuccess: var(--notification-success-color);
$notificationSuccessText: var(--notification-success-text-color);

// interface colors
$body-bg: var(--body-bg-color);
$internal-body-bg: var(--internal-body-bg-color);
$background-color-alpha: var(--background-color-alpha);
$backdrop-filter-alpha: var(--backdrop-filter-alpha);
$body-color: var(--body-color);
$text-dark: var(--body-color);
$text-light: var(--text-light);
$text-btn: var(--text-btn);
$const-dark-color: var(--const-dark-color);
$const-light-color: var(--const-light-color);
$border-color: var(--border-color);
$accordion-content-color: var(--accordion-content-color);
$event-tab: var(--event-tab);
$tab-bg: var(--tab-bg);
$tab-color: var(--tab-color);
$ai-chat-background: var(--ai-chat-background);
$ai-button-selected-background: var(--ai-button-selected-background);
$form-background: var(--form-background);
$filter-selected-status: var(--filter-selected-status);
$slide-toogle-active: var(--slide-toggle-active);
$avatar-bg: var(--avatar-background);
$table-bg: var(--table-bg);
$brand-primary-filter: var(--brand-primary-filter);
$selected-field: var(--selected-field);
$dialog-bg: var(--dialog-bg);
$hover-color: var(--hover-color);
$description-color: var(--description-color);

$table-bg-white: var(--table-bg-white);
$table-bg-grey: var(--table-bg-grey);
$table-bg-grey-row: var(--table-bg-grey-row);
$table-row-hover: var(--table-row-hover);

$rsvp-background-color: var(--rsvp-background-color);
$rsvp-backdrop-filter: var(--rsvp-backdrop-filter);
$rsvp-hover-color: var(--rsvp-hover-color);

$event-edit-field-bg-color: var(--event-edit-field-bg-color);

$user-profile-edit-bg-color: var(--user-profile-edit-bg-color);

$selected-action: var(--selected-action);
$action-bar-hover: var(--action-bar-hover);

$system-dialog-bg-color: var(--system-dialog-bg-color);

$use-case-card-hover: var(--use-case-card-hover);

$color-palette-background: var(--color-palette-background);
$color-palette-border: var(--color-palette-border);

$matFormFieldBackgroundColor: var(--mat-form-field-background-color);
$matFormFieldFocusedBackgroundColor: var(
  --mat-form-field-focused-background-color
);
$matFormFieldBorderColor: var(--mat-form-field-border-color);
$matFormFieldFocusedBorderColor: var(--mat-form-field-focused-border-color);
$matFormFieldInputColor: var(--mat-form-field-input-color);
$matFormFieldLabelColor: var(--mat-form-field-label-color);
$matFormFieldLabelFocusedColor: var(--mat-form-field-label-focused-color);

$matFormFieldDisabledInputColor: var(--mat-form-field-disabled-input-color);
$matFormFieldDisabledLabelColor: var(--mat-form-field-disabled-label-color);

$matDrawerBackground: var(--mat-drawer-background);
$mat-datepicker-disabled-date-label: var(--mat-datepicker-disabled-date-label);
$matCalendarSelectedDate: var(--mat-calendar-selected-date);
$matDaterangePickerSelectedTime: var(--mat-daterange-picker-selected-time);
$matDaterangePickerSelectedDate: var(--mat-daterange-picker-selected-date);
$iconButtonHover: var(--icon-button-hover);

$communityMatFormFieldFocusedBorderColor: var(
  --community-mat-form-field-focused-border-color
);
$communityMatFormFieldLabelFocusedColor: var(
  --community-mat-form-field-label-focused-color
);

$timezone-select-label-color: var(--timezone-select-label-color);

$mat-calendar-selected: var(--mat-calendar-selected);
$mat-calendar-hover: var(--mat-calendar-hover);
$mat-calendar-range: var(--mat-calendar-range);

$normalDialogBackgroundColor: var(--normal-dialog-background-color);
$normalDialogEventBackgroundColor: var(--normal-dialog-event-background-color);

$eventThemePrimaryGlowColor: var(--event-theme-primary-glow);
$eventThemePrimaryColor: var(--event-theme-primary-color);
$eventThemeBodyFontColor: var(--event-theme-body-color);
$eventThemeSecondaryColor: var(--event-theme-secondary-color);
$eventThemeTernaryColor: var(--event-theme-ternary-color);
$eventThemeAccentColor: var(--event-theme-accent-color);
$eventThemePopColor: var(--event-theme-pop-color);
$eventThemeActionColor: var(--event-theme-action-color);
$eventThemePrimaryTextColor: var(--event-theme-primary-text-color);
$eventThemePrimaryTextOppositeColor: var(
  --event-theme-primary-text-opposite-color
);
$eventThemeAccentTextColor: var(--event-theme-accent-text-color);
$eventThemePopTextColor: var(--event-theme-pop-text-color);
$eventThemeBrandColor: var(--event-theme-brand-color);
$eventThemeSignalColor: var(--event-theme-signal-color);
$eventThemeBackgroundColor: var(--event-theme-background-color);
$eventThemeSecondaryTextColor: var(--mat-form-field-input-color);
$eventThemeBackColor: var(--event-theme-back);
$eventThemeFrontColor: var(--event-theme-front);
$eventThemePrimaryColorOpacity: var(--event-theme-primary-color-opacity);
$eventThemeActionsBgColor: var(--event-theme-actions-bg-color);
$eventThemeActionsBgColorHover: var(--event-theme-actions-bg-color-hover);

$rsvpDialogBackgroundColor: var(--rsvp-dialog-background-color);
$rsvpDialogBackdropFilter: var(--rsvp-dialog-backdrop-filter);
$rsvpDialogBodyColor: var(--text-primary);
$eventThemeRsvpPrimaryColor: var(--event-theme-rsvp-primary-color);
$eventThemeRsvpPrimaryTextColor: var(--event-theme-rsvp-primary-text-color);
$eventThemeRsvpActionColor: var(--event-theme-rsvp-action-color);
$mobileNavBackgroundColor: var(--mobile-nav-background-color);
$mobileNavBackgroundColorFullOpacity: var(
  --mobile-nav-background-color-full-opacity
);
$mobileNavBackdropFilter: var(--mobile-nav-backdrop-filter);

$sectionHeadlineColor: var(--event-section-headline-color);
$sectionHeadlineBackgroundColor: var(--event-section-headline-background-color);
$sectionBodyColor: var(--event-section-body-color);
$sectionBackgroundColor: var(--event-section-background-color);
$sectionMainBodyColor: var(--event-section-main-body-color);
$sectionMainBackgroundColor: var(--event-section-main-background-color);

$sectionAccentHeadlineColor: var(--event-section-accent-headline-color);
$sectionAccentHeadlineBackgroundColor: var(
  --event-section-accent-headline-background-color
);
$sectionAccentBodyColor: var(--event-section-accent-body-color);
$sectionAccentBackgroundColor: var(--event-section-accent-background-color);

$event-link-bio-background-color: var(--event-link-bio-background-color);
$event-link-bio-title-color: var(--event-link-bio-title-color);
$event-link-bio-body-color: var(--event-link-bio-body-color);

$opacity-dialog: var(--opacity-dialog);
$background-blurred: var(--background-blurred);

$referral-card-cutout: var(--referral-card-cutout);

$profile-events-bg: var(--profile-events-bg);
$user-avatar-shadow: var(--user-avatar-shadow);

$create-event-start-type-bg: var(--create-event-start-type-bg);

$card-bg: var(--card-bg);

$dashboard-statistic-card-bg: var(--dashboard-statistic-card-bg);

$dashboard-navigation-card-bg: var(--dashboard-navigation-card-bg);
$dashboard-navigation-card-border: var(--dashboard-navigation-card-border);

$bottom-sheet-dialog-bg: var(--bottom-sheet-dialog-bg);
$dialog-backdrop-bg: var(--dialog-backdrop-bg);
$secondary-dialog-backdrop-bg: var(--secondary-dialog-backdrop-bg);
